.Contact-section{
    background-color: white;
    margin: 0 !important;
    padding: 0 !important;
    width: 100% !important;
    color: black;
}

.precontact{
    background-color: #153E90;
    height: 40px;
    margin: 3vw;
}

.vr{
display:inline;
height:5vw;
width:1px;
border:1px inset;
margin: 0 4vw
}
.contactLeft{
    display: inline-block;

}

.media-links{
    display: inline;
    margin: 0 1vw;
    font-size: 24px;
}