@import url('https://fonts.googleapis.com/css2?family=Exo:wght@100;300;900&family=Roboto:wght@100&display=swap');

body{
  margin: 0;

}

.App {
  text-align: center;
  margin: 0;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.App-header {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #61dafb;
}
.solar-green{
  color: #54E346;
}

.youtube{
  position: sticky;
  display: none;
}

/* .Youtube-section{
  position: relative;
  width: 100%;
  height: 100vh;
  padding-bottom: 56.25%;
  
} */

.exo-font{
  font-family: 'Exo', sans-serif;
  font-weight: 900;
}

.text-shadow2{
  text-shadow: -2px 1px black;
  color: white;
}
.text-shadow3{
  text-shadow: -2px 1px white;
  color: black;
}

.video-player{
  width: 100%;
  height: 66vw;
}

.Youtube-section {
  position: absolute;
  padding-bottom: 56.25%;
  overflow: hidden;
  width: 100%;
}

.Youtube-section iframe {
  position: relative;
  width: 170%;
  height: 140%;
  top: 0;
  left: -10%;
  border: 0;
}

.rs-fullvideo-cover {
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  position: absolute;
  background: transparent;
  z-index: 3;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
