.Welcome-section{
height: 756px;
background-image: url(../Images/PanelImage.jpg);
background-position: center;
background-repeat: no-repeat;
background-size: cover;
}


.Welcome-text{
    margin: 5vw;
    position: relative;
    text-align: center;
justify-content: center;
z-index: 6;
}

.empty-bed{
    height: 9vw;
    background: transparent;
}

.Welcome-text h1{
    width: 100%;
}

.text-shadow{
    text-shadow: 1px 1px black;
}
.title{
    display: block !important;
}

.Footer-section{
    margin: 4vw;
}
/* .Welcome-image{
    width: auto;
    height: 100px;
} */