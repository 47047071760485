.WhySolar-section{
    position: relative;
    margin:0;
    background-color: white;
    z-index: 5;
    padding: 2rem;
}

.WhySolar-section h1{
    margin: 2vw;
}

path{
    -webkit-transition: color 1s;
    transition: color 1s ease;
}

.card:hover h1 .cardIcon path{
/* color: rgb(84,227,70) !important;  */

    color: #fffaa4 !important;

}

