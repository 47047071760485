

.About-section{
    background-color: #0E49B5 ;
    color: #cbebc9;
    text-shadow: 2px 1px black;
    width: 100%;
    position: relative;
    z-index: 6;
    padding: 2rem;
    box-shadow: 0 0 5px 0 black;
}

@media only screen and (max-width: 600px) {
    .About-section h4 {
      font-size: 1rem !important;
    }
    .About-section h1 {
        font-size: 1.7rem !important;
      }
  }