.header-bg-color{
    background-color: #0E49B5 !important;
}


.narBarColor{
    z-index: 100;
    background-color: none;
    -webkit-transition: color 5s;
    transition: color 5s ease;

}

.narBarColor{
/* background-color: #0E49B5; */
display: none;

}
.narBarColor a{

    color: white !important;

    }

